<template>
  <div v-if="type && type">
    <v-card-text class="pt-1 pb-2">
      <span class="text-h5 text--darken-1 ">
        Application:
      </span>
      {{ type }}
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "applyDirect",
  components: {},
  props: {
    structuredData: Object
  },
  data() {
    return {};
  },
  computed: {
    type() {
      return this.structuredData?.value?.M_C_YN_applyDirect?.value === "Yes"
        ? "Apply directly to institution"
        : "Apply through UAC";
    }
  }
};
</script>

<style lang="scss" scoped></style>
