/* eslint-disable */

export default new (class {
  add({ map, appSettings, parentThis }) {
    if (appSettings || parentThis || map) {
      //do nothing!!
    }
    /*

    map.addControl(
      new MapboxAccessibility({
        accessibleLabelProperty: "index",
        layers: ['places_unclustered_screenreader', 'places_clustered_screenreader']
      })
    );











map.addSource('data', {
  'type': 'geojson',
  'data': {
    'type': 'FeatureCollection',
    'features': [{
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates':  [160.775, -25.2744]
      },
      'properties': {
        'description': 'Marker One, this marker uses a monument as an icon',
        'title': 'Marker One',
        'icon': 'monument'
      }
    }, {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [160.775, -10]
      },
      'properties': {
        'description': 'Marker Two, this marker uses an anchor as an icon',
        'title': 'Marker Two',
        'icon': 'harbor'
      }
    },]
  }
});

map.addLayer({
  'id': 'points',
  'type': 'symbol',
  'source': 'data',
  'layout': {'icon-size': 1.5,
    'icon-image': '{icon}-15',
    'text-field': '{title}',
    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
    'text-offset': [0, 0.6],
    'text-anchor': 'top'
  },
  //'filter': ['==', '$type', 'Point']
  'filter': ['all',['==', '$type', 'Point']]

});



map.addLayer({
  id: "points_proxyLayer",
  type: "circle",
  'source': 'data',

  paint: {
    //...styles?.clustered_paint,
    "circle-color": "green",
    "circle-radius": 20,
    "circle-opacity":1,
  }
});






map.on('click', 'points', (e) => {
// Copy coordinates array.
const coordinates = e.features[0].geometry.coordinates.slice();
const description = e.features[0].properties.description;

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
}

new mapboxgl.Popup()
.setLngLat(coordinates)
.setHTML(description)
.addTo(map);
});







map.addControl(new MapboxAccessibility({
  accessibleLabelProperty: 'name',
  layers: [
   'places_unclustered_screenreader'
  ]
}));
*/

    //map.getSource("places")
/*
    setTimeout(() => {
      let thisSourceData = map.getSource("places")._data;
    }, 1000);
*/
    map.addControl(
      new MapboxAccessibility({
        accessibleLabelProperty: "name", //Institute_Name_And_Point_Name
        layers: [ "places_clustered_screenreader", "places_unclustered_screenreader"],
      })
    );
  }
})();
