export default {
  data: () => ({}),

  computed: {},

  methods: {
    createTooltip(e) {
      /*
      console.log("createTooltip", { e });
      console.log("click", e.features.length);
      console.log("click", e.features);*/

      try {
        this.clearTooltip();
        let coordinates = e.features[0].geometry.coordinates.slice();

        let title;

        let styleBgColor = "black";
        let styleTextColor = "white";

        try {
          if (
            this?.appSettings?.defaultStyleCat &&
            this.categoryLookup &&
            this.categoryLookup[this.appSettings.defaultStyleCat]?.color
          ) {
            styleBgColor = this.categoryLookup[this.appSettings.defaultStyleCat]
              .color;
          }
        } catch (error) {
          //console.log({error});
        }

        let type = "none;";

        if (e.features[0]?.properties?.Institute_Name_And_Point_Name) {
          title = e.features[0]?.properties?.Institute_Name_And_Point_Name;
          try {
            if (
              e.features[0]?.properties?.section_slug &&
              this.categoryLookup &&
              this.categoryLookup[e.features[0]?.properties?.section_slug]
                ?.color
            ) {
              styleBgColor = this.categoryLookup[
                e.features[0].properties.section_slug
              ].color;
            }
          } catch (error) {
            //do nothing
          }
          type = "a;";
        } else if (e.features[0]?.properties?.Point_Name) {
          title = e.features[0].properties.Point_Name;
          type = "b;";
        } else if (e.features[0]?.properties?.marker_point_name) {
          title = e.features[0].properties.marker_point_name;
          type = "c;";
          try {
            let marker_color_sets = e.features[0].properties.marker_color_sets;

            if (marker_color_sets && marker_color_sets.includes(styleBgColor)) {
              //nothing to do;
            } else {
              let tempcolor = marker_color_sets.split(",")[0];
              styleBgColor =
                tempcolor && tempcolor.length && tempcolor.length > 3
                  ? tempcolor
                  : styleBgColor;
            }
          } catch (error) {
            //do nothing
          }
        } else if (e.features[0]?.properties?.marker_indexs) {
          type = "d;";
          title = e.features[0].properties.marker_indexs
            .split(",")
            .slice(0, 3)
            .filter(
              index =>
                (index || index == 0) &&
                !isNaN(index) &&
                this.sites[parseFloat(index)]
            )
            .map(index => this.sites[parseFloat(index)]?.properties?.Point_Name)
            .join(", ");
        }
        if (!type) console.log({ title, type });

        if (title) {
          try {
            title = title
              .split(",")
              .map(item => {
                let words = item.trim().split(" ");
                if (!isNaN(words[words.length - 1].trim())) {
                  words.pop();
                }

                return words.join(" ");
              })

              .filter(index => index || (index == 0 && index != ""))
              .join(", ");
          } catch (e) {
            //do ntohing
          }

          //console.log("tooltip", { type }, title, styleBgColor, styleTextColor);

          if (styleBgColor || styleTextColor) {
            //do nothing;
          }

          this.toolTipAct = new window.mapboxgl.Popup({
            className: "pointPopupTooltip",
            //closeOnMove: true
            closeButton: false,
            anchor: "top" //offset: [0, -20],
          })
            .setLngLat(coordinates)
            .setHTML(
              `<div class="mvToolTip" style="xbackground:${styleBgColor};color:${styleBgColor}">${title}</div>`
            )
            .addTo(this.map);
        }
      } catch (error) {
        //do nothing
        if (!error) {
          //do nothing
        }
      }
    },
    clearTooltip() {
      try {
        if (this.toolTipAct) {
          this.toolTipAct.remove();
          this.toolTipAct = null;
        }
      } catch (error) {
        //do nothing
        if (!error) {
          //do nothing
        }
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
