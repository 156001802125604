<template>
  <div class="dataTableView">
    <div
      style="  max-height: calc(100vh - 156px);     overflow: auto;"
      v-if="false"
    >
      <div v-for="item in listItems" :key="item.key">
        <h1>{{ item.key }}</h1>
        <h1>{{ item.data.name }}</h1>

        <div
          class="Point_Description_HTML"
          v-html="item.data.structuredData.note.M_TA_description.value"
        ></div>

        <hr />
        {{ item.data.structuredData.note.M_TA_description.value }}
        <hr />
      </div>
    </div>
    <v-card width="100%" height="100%">
      <v-card-title>
        <v-text-field
          v-model="markerSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        class="dataTableWrapper"
        width="100%"
        height="100%"
        :headers="[
          //{ text: 'Logo', value: 'logo', filterable: true },
          {
            text: 'Institution',
            value: 'list_section_title',
            filterable: true
          },
          { text: 'Campus', value: 'Point_Name', filterable: true },

          {
            text: 'Application type',
            value: 'InstituteType',
            filterable: true
          },

          {
            text: 'State',
            value: 'section_slug_upper',
            filterable: true
          },
          {
            text: 'Address',
            value: 'address',
            filterable: true,
            sortable: false
          },

          {
            text: 'Controls',
            value: 'post_id',
            filterable: false,
            sortable: false
          }
        ]"
        :items="markerListData"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-options': [itemsPerPage, ...itemPerPageOption]
        }"
        :search="markerSearch"
      >
        <template v-slot:item.logo="{ item }">
          <div>
            <v-img :src="item.logo" width="50px"></v-img>
          </div>
        </template>

        <template v-slot:item.post_id="{ item }">
          <div>
            <v-btn
              text
              @click="
                $event => {
                  loadSite(item.index, { showMap: false });
                }
              "
            >
              Show More Info
            </v-btn>

            <v-btn
              :color="appSettings.dialogActionButtonColor"
              text
              @click="
                $event => {
                  loadSite(item.index, { showMap: true });
                }
              "
            >
              Show On Map
            </v-btn>
          </div>
        </template>

        <template v-slot:item.index="{ item }">
          <div>
            <v-btn
              :color="appSettings.dialogActionButtonColor"
              text
              @click="
                $event => {
                  loadSite(item.index, { showMap: true });
                }
              "
            >
              Show On Map
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import { eventBus } from "./../../main";

export default {
  name: "TableView",

  data() {
    return {
      itemsPerPage: -1,
      itemPerPageOption: [5, 10, 25, -1],

      markerSearch: "",
      iconSize: 40
    };
  },

  props: {},

  methods: {
    loadSite(index, { showMap = false }) {
      console.log("load site");

      let thisSourceData = window.map.getSource("places")._data;

      //checking if point is hidden by filters;
      let found = 0;
      try {
        found = thisSourceData.features.filter(feature => {
          return (
            feature?.properties?.index === index &&
            feature?.properties?.hide === false
          );
        }).length;
      } catch (error) {
        //console.log({ error });
      }

      if (found === 0) {
        //point is hidden, reset filters first;
        eventBus.$emit("nav_map_reset");
      }

      setTimeout(
        () => {
          let site = this.sites.filter(row => {
            return row.properties.index == index;
          })[0];
          eventBus.$emit("flyToPoint", "markerList", site);

          if (showMap === true) {
            eventBus.$emit("nav_SetTableMapView", "map");
          } else if (found !== 0) {
            eventBus.$emit("nav_layout_openMenu");
          }
        },
        found === 0 ? 50 : 0
      );
    }
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,

      categoryLookup: state => state.markers.categoryLookup,
      listCategoryLookup: state => state.markers.listCategoryLookup
    }),

    listItems() {
      let values = [];

      let keys = _.keys(this.listCategoryLookup);

      console.log(this.markerList);

      keys.forEach(key => {
        let [match] = this.markerList.data.features.filter(
          row => row.properties.list_section_slug === key
        );

        console.log({ key, match });

        //this.properties?.structuredData?.note?.M_TA_description?.value;

        let data = {
          key,
          data: match.properties
        };
        values.push(data);
      });
      return values;
    },

    listCategoriesLayers() {
      return Object.keys(this.listCategoryLookup);
    },
    sites() {
      return this.markerList.data.features;
    },

    markerListData() {
      return this.sites.map(row => {
        let rowProps = JSON.parse(JSON.stringify(row.properties));

        rowProps.logo = "";

        try {
          rowProps.logo = this.listCategoryLookup[
            rowProps.list_section_slug
          ].logo_image_obj.href;
        } catch (error) {
          //do nothing;
        }

        rowProps.address = "";
        try {
          rowProps.address =
            rowProps?.structuredData?.subtitle?.M_ST_Point_Address?.value;
        } catch (error) {
          //do nothing!!;
        }

        rowProps.list_section_slug_upper = rowProps.list_section_slug.toUpperCase();
        rowProps.section_slug_upper = rowProps.section_slug.toUpperCase();

        rowProps.InstituteType = "Apply through UAC";
        try {
          if (rowProps.structuredData.value.M_C_YN_applyDirect.value == "Yes") {
            rowProps.InstituteType = "Apply directly to institution";
          }
        } catch (error) {
          //do nothing!!;
        }

        let {
          logo,
          address,
          index,
          slug,
          post_id,
          list_section_slug,
          list_section_slug_upper,
          list_section_title,
          Point_Name,
          InstituteType,
          section_slug_upper
        } = rowProps;

        return {
          logo,
          Point_Name,
          index,
          slug,
          post_id,
          list_section_slug,
          list_section_slug_upper,
          list_section_title,

          address,
          InstituteType,
          section_slug_upper
        };
      });
    }
  }
};
</script>

<style lang="scss">
.dataTableView {
  width: 100vw;
}

.dataTableWrapper .v-data-table__wrapper {
  max-height: calc(100vh - 240px);

  min-height: calc(100vh - 240px);
}

.dataTableWrapper .v-data-table__wrapper table {
  text-align: left;
  position: relative;
}

.dataTableWrapper .v-data-table__wrapper th {
  background: white !important;
  position: sticky;
  top: 0;
  z-index: 1;
  min-width: 90px;
}

.dataTableWrapper th {
  min-width: 90px;
  white-space: nowrap;
}

.dataTableView .dataTableWrapper {
  text-align: left;
  position: relative;
}

.dataTableView .dataTableWrapper .v-data-footer {
  background: white !important;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.dataTableWrapper .v-data-table__wrapper tr:last-child {
  margin-bottom: 65px;
}
</style>
