var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? 500 : 900},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"linkButton"},'span',attrs,false),on),[(!_vm.$vuetify.breakpoint.xsOnly)?_c('font',{staticClass:"secondary--text "}):_vm._e(),_vm._v("Acknowledgement"),(!_vm.$vuetify.breakpoint.xsOnly)?_c('font',[_vm._v(" of Country ")]):_vm._e()],1)]}}]),model:{value:(_vm.openIntro),callback:function ($$v) {_vm.openIntro=$$v},expression:"openIntro"}},[_c('v-card',{staticStyle:{"overflow":"hidden"}},[_c('v-card-title',{staticClass:"  xlighten-2 white--text text-center justify-center siteHeadingText",style:({
          background: 'rgb(100 54 91)'
        })},[_vm._v(" Acknowledgement of Country ")]),_c('div',{class:{ mobileSizeDialog: _vm.$vuetify.breakpoint.xsOnly }},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{staticClass:"contentWrapper noOverflowOnWide",staticStyle:{"background-image":"url(assets/Dreams-by-Adam-Hennessy-purple-version.jpg)","background-size":"cover","min-height":"207px","background-repeat":"no-repeat","border-bottom":"15px #64365b solid"}},[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticClass:"ma-0 pa-0 bodyTextAck",attrs:{"md":"4","cols":"4"}},[_c('v-card-text',[_c('center',[_c('h3',{staticClass:"white--text font-weight-light"},[_vm._v(" UAC acknowledges and pays respect to the past, present and future traditional custodians and elders of this nation. We honour the continuation of cultural, spiritual and educational practices of Aboriginal and Torres Strait Islander peoples. ")])])],1)],1)],1):_vm._e(),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{staticClass:"contentWrapper noOverflowOnWide",style:({
            background: 'rgb(100 54 91)'
          })},[_c('v-col',{staticClass:"pa-3",staticStyle:{"overflow":"hidden","max-height":"100%"},attrs:{"cols":"12"}},[(
                _vm.$vuetify.breakpoint.mdAndUp || _vm.$vuetify.breakpoint.smAndDown
              )?_c('v-img',{staticClass:"white--text align-end  ma-0",attrs:{"src":"assets/Dreams-by-Adam-Hennessy-purple-version.jpg"}}):_vm._e()],1),_c('v-col',{staticClass:"ma-0 pa-0 bodyTextAck",attrs:{"cols":"12"}},[_c('v-card-text',[_c('center',[_c('h3',{staticClass:"white--text font-weight-light"},[_vm._v(" UAC acknowledges and pays respect to the past, present and future traditional custodians and elders of this nation. We honour the continuation of cultural, spiritual and educational practices of Aboriginal and Torres Strait Islander peoples. ")])])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"contentWrapper noOverflowOnWide"},[_c('v-col',{staticClass:"ma-0 pa-0 bodyTextAck",attrs:{"md":"12","cols":"12"}},[_c('v-card-text',[_c('center',[_c('v-col',{staticClass:"ma-0 pt-0 bodyTextAck",attrs:{"md":"12","cols":"12"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('center',[_c('p',{staticClass:"pt-0"},[_vm._v(" Original artwork 'Dreams' by artist Adam Hennessy – The circles represent coming together. They form a pathway around the outside, symbolising an educational journey. The circles in the top right are our institutions gathered to fulfil the dreams of the travellers. ")])])],1)],1)],1)],1)],1)],1),_c('v-divider')],1),_c('v-card-actions',[_c('v-spacer'),(false)?_c('a',{staticStyle:{"text-decoration":"none!important"},attrs:{"href":"http://maptivate.com.au/","target":"_blank"}},[_c('v-btn',{attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""}},[_vm._v(" More Information ")])],1):_vm._e(),(false)?_c('v-btn',{attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function($event){return _vm.loadAbout()}}},[_vm._v(" About Maptivate ")]):_vm._e(),_c('v-btn',{attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function($event){_vm.openIntro = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }