import _ from "lodash";
import { mapState } from "vuex";

export default {
  data: () => ({}),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings
    })
  },

  methods: {
    updatefilters() {
      let filters = this.layers;
      let options = this.applicationTypeFilters;

      let dataSouces = _.values(this.filterableDataSouceIds);
      dataSouces.forEach(source => {
        let thisSourceData = this.map.getSource(source)._data;
        thisSourceData.features
          //copyFilterCodeFromHere
          .forEach(feature => {
            let filterMatch = true;
            let layerMatch = true;

            try {
              let applyDirect =
                feature?.properties?.structuredData?.value?.M_C_YN_applyDirect
                  ?.value == "Yes"
                  ? true
                  : false;
              filterMatch =
                options.includes("All") ||
                (options.includes("Participating") && applyDirect === false) ||
                (options.includes("Apply Direct") && applyDirect === true)
                  ? true
                  : false;
            } catch (error) {
              //do nothing!!
            }

            try {
              layerMatch = filters.includes(feature.properties.section_slug)
                ? true
                : false;
            } catch (error) {
              //do nothing!!
            }

            if (layerMatch && filterMatch) {
              feature.properties.hide = false;
              feature.geometry.coordinates = [
                feature.properties.Latitude,
                feature.properties.Longitude
              ];
            } else {
              feature.properties.hide = true;
              feature.geometry.coordinates = [0, 0];
            }
          });
        //copyFilterCodeToHere

        this.map.getSource(source).setData(thisSourceData);
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
