let colors = {
  primary: "#1E2245", // (UAC Midnight Blue)
  secondary: "#394049", // (UAC Charcoal)
  accent: "#448532", // (UAC Lime Green)
  background: "#F1F1F1", // (UAC Light Grey)
  sidedraw: "#FFFFFF", // (White)
  headerBarBack: "#1E2245", // (UAC Midnight Blue)
  footerBarBack: "#1E2245", // (UAC Midnight Blue)

  //primary: "#1e2245",
  //secondary: "#394049",
  //accent: "#",
  xxxprimary: "#",
  xxxxprimary: "#",
  xxxxxprimary: "#",
  xxxxxxprimary: "#",
  xxxxddxxprimary: "#"
};

const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};
const state = {
  maptivateSetupObject: {},
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoiaW5mby1tYXB0aXZhdGUiLCJhIjoiY2xmbTF1Ym9wMDdmczQxbW9vcmdmYm92cCJ9.UgrbRYg9Chk1JJzNvgnQNw",
      options: {
        //style: "mapbox://styles/lilygilby/cl8novx3l000415o6qbarj9tl",
        style: "mapbox://styles/info-maptivate/clhefn2fy00f501pw4zrc4bva",
        center: [133.7751, -25.2744],
        maxZoom: 22,
        minZoom: 1,
        pitch: 0,
        bearing: 0,
        zoom: 1
      },
      xbounds: [
        [143.859, -37.5799], // Southwest coordinates
        [143.871, -37.575] // Northeast coordinates
      ],

      defaultSetup: {
        center: [149.09997, -33.240123],
        zoom: 4,
        zoomTab: 3,
        zoomMob: 3,
        pitch: 0,
        bearing: 0
      },

      flyToBounds: [
        [113, -8], // southwestern corner of the bounds
        [153.5, -44.1] // northeastern corner of the bounds
      ],

      disableRotationAndBearing: false
    },

    wpEditSite: "https://sh-dev.maptivateapp.com.au/wp/wp-admin/post.php?post=",

    markerIsPointer: false,
    showSiteNumbers: false,
    showSiteNumbers_Labels: false,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    defaultStyleCat: "nsw",

    layerDefaults: {
      places: {
        scaling: {
          minZoom: 1,
          opacityMinZoom: 4,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 4,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: false,
        hoverState: true,
        haveToolTip: true,

        screenReaderHotspot: true,
        styles: {
          unclustered_paint: { "circle-color": "#ff0000" },
          clustered_paint: { "circle-color": "#354d64" }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterLabel: true,
        clusterSetup: {
          cluster: true,
          clusterMaxZoom: 10,
          clusterRadius: 30,

          clusterProperties: {
            // keep separate counts for each magnitude category in a cluster
            nsw: ["+", ["case", ["in", ["get", "section_slug"], "nsw"], 1, 0]],
            vic: ["+", ["case", ["in", ["get", "section_slug"], "vic"], 1, 0]],
            qld: ["+", ["case", ["in", ["get", "section_slug"], "qld"], 1, 0]]
          }
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },

      signage: {
        scaling: {
          minZoom: 16,
          opacityMinZoom: 16,
          opacityMaxZoom: 17,

          clusterOpacityMinZoom: 1,
          clusterOpacityMaxZoom: 5,
          unclusterScale: 0.85
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 17, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      }
    },

    /*categoryLookup: {
      History: {
        icon: "history_marker",
        full: "history_full",
        logo: "history_icon",
        title: "Our Stories",
        description: "",

        color: "#626f60",
        padding: "15px"
      },

      "Prospective Families": {
        icon: "prospective_marker",
        full: "prospective_families_full",
        logo: "prospective_icon",
        title: "Our School",
        description: "",
        color: "#8e3839",
        padding: "15px"
      },

      Wayfinding: {
        icon: "wayfinding_marker",
        full: "wayfinding_full",
        logo: "wayfinding_icon",
        title: "Our Spaces",
        description: "",
        color: "#e0c381",
        padding: "6px"
      }
    },
*/
    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    autoOpenMoreInfo: true,
    drawWidth: 450,
    mobDrawWidth: 300,

    popupButtonWidth: "224px",
    drawButtonWidth: "372px",
    menuBarColor: colors.primary, //"#9ec0bd",
    buttonDarkColor: colors.secondary, //"#9ec0bd",

    mediaButtonColor: colors.primary, //"#9ec0bd",
    menuBarColorSecondary: colors.accent, //"#9ec0bd",
    darkBackText: "#ffffff", //"#9ec0bd",
    appButtonColor: "#1f3531",
    dialogActionButtonColor: "#1f3531",

    audioPlayColor: "1f3531",
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerText: "#fcfbf1"
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
