<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span class="linkButton" v-bind="attrs" v-on="on"> About </span>
      </template>

      <v-card>
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 white--text text-center justify-center siteHeadingText"
        >
          {{ title }}
        </v-card-title>
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row no-gutters class="contentWrapper  secondary--text " style="">
            <v-col cols="12" md="6" class="pt-3 px-4">
              <center v-if="$vuetify.breakpoint.smAndDown">
                <img
                  class="heroImage"
                  src="assets/landingPages/heroImage.jpg"
                  style="max-width:100%"
                />

                <v-img
                  src="assets/landingPages/logo.png"
                  max-width="200"
                  class="mb-6 "
                />
              </center>

              <div v-if="!$vuetify.breakpoint.smAndDown">
                <img
                  class="heroImage"
                  src="assets/landingPages/heroImage.jpg"
                  style="max-width:100%"
                />

                <v-img
                  src="assets/landingPages/logo.png"
                  max-width="200"
                  class="mb-6 "
                />
              </div>
            </v-col>
            <v-col cols="12" md="6" class="pt-3 pl-4 welcomeText">
              <div class="scrollingContent pr-4 ">
                <p>
                  The Universities Admissions Centre (UAC) processes
                  applications for admission to most undergraduate and many
                  postgraduate courses at participating institutions. UAC
                  calculates and provides the Australian Tertiary Admission Rank
                  (ATAR) and processes applications for Educational Access
                  Schemes, Equity Scholarships and Schools Recommendation
                  Schemes.
                </p>
                <p>
                  UAC is committed to providing excellent service to its
                  applicants as well as to tertiary institutions, schools,
                  parents, government education departments, statutory
                  authorities and other bodies.
                </p>
                <p v-if="false">
                  <v-img
                    src="assets/landingPages/gettingstarted/layers.png"
                    max-width="60%"
                    ex
                  />
                </p>

                <p>
                  You can use our interactive Maptivate map to explore
                  institutions in your area and further afield.
                </p>
                <p>
                  The map has two layers which you can turn on or off to show
                  participating institutions or apply direct institutions. You
                  can also choose to show or hide your state of interest: NSW,
                  ACT, VIC, QLD, SA, NT or WA.
                </p>

                <table class="abooutTable">
                  <tr>
                    <td class="iconCell">
                      <center>
                        <img
                          src="assets/landingPages/gettingstarted/layerButton.png"
                          width="60px"
                        />
                      </center>
                    </td>
                    <td class="textCell">
                      Access the layers with this button in the top right of
                      your screen
                    </td>
                  </tr>

                  <tr>
                    <td class="iconCell">
                      <center>
                        <img
                          src="assets/landingPages/gettingstarted/marker.png"
                          width="30px"
                        />
                      </center>
                    </td>
                    <td class="textCell">
                      Click on the map point to reveal more information and
                      links about that campus
                    </td>
                  </tr>

                  <tr>
                    <td class="iconCell">
                      <center>
                        <img
                          src="assets/landingPages/gettingstarted/locationButton.png"
                          width="40px"
                        />
                      </center>
                    </td>
                    <td class="textCell">
                      Click on this button to reveal your location on the map
                    </td>
                  </tr>

                  <tr>
                    <td class="iconCell">
                      <center>
                        <img
                          src="assets/landingPages/gettingstarted/mapTableTab.png"
                          width="90px"
                        />
                      </center>
                    </td>
                    <td class="textCell">
                      Use this button to switch between map and table format
                    </td>
                  </tr>
                </table>
              </div>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn
              v-if="false"
              :color="appSettings.dialogActionButtonColor"
              text
            >
              More Information
              <font v-if="!$vuetify.breakpoint.smAndDown">About Maptivate</font>
            </v-btn>
          </a>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",

  data() {
    return {
      dialog: true,
      title: "Welcome to Maptivate UAC"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  },
  mounted() {}
};
</script>

<style>
table.abooutTable tr {
}

.iconCell {
  width: 100px;
  vertical-align: top;
}
.textCell {
  padding-bottom: 10px;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media only screen and (min-width: 961px) {
  .welcomeText .scrollingContent {
    max-height: 450px;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 960px) {
  .heroImage {
    width: 290px !important;
  }

  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }

  .contentWrapper {
    xmax-height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 960px) {
  .contentWrapper {
    max-height: calc(100vh - 200px);
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper {
    max-height: calc(100vh - 240px);
  }
}
</style>
