import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#ff0000",",
        ////accent: "#476B63",
        //// error: "#546B66"
        //"primary--text": "#ff0000"

        primary: "#1e2245",
        secondary: "#394049",
        accent: "#448532", //link text
        error: "#1e2245", //item se
        ////secondary: "#394246lected
        info: "#a5a7b5", //
        //success: "#00F",
        //warning: "#00F"
        xxwarning: "#ff0000" //marker text
      },

      dark: {
        //primary: "#ff0000",",
        ////accent: "#476B63",
        //// error: "#546B66"
        //"primary--text": "#ff0000"

        primary: "#1e2245",
        secondary: "white",
        accent: "#FFFFFF", //link text
        error: "#1e2245", //item se
        ////secondary: "#394246lected
        //info: "#fcfbf1",//
        //success: "#00F",
        //warning: "#00F"
        xxwarning: "#fcfbf1" //marker text
      }
    }
  }
});
