<template>
  <v-app :class="{ 'my-app': true, xsOnly: $vuetify.breakpoint.xsOnly }">
    <Layout v-if="ready" />
  </v-app>
</template>

<script>
import Layout from "./Layout.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  async mounted() {
    this.$store.dispatch("wpData_initData", { vm: this });
  },
  components: {
    Layout
  },

  data: () => ({
    ready: false
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      wpdata: state => state.wpdata.wpdata
    })
  },

  watch: {
    wpdata: function(newValue) {
      if (newValue?.categoryOrder.length) {
        this.$store.dispatch("global_updateMaptivateSetupObject", newValue);
        this.$store.dispatch("markers_updateData", newValue);
        this.$store.dispatch("filters_updateData", newValue);

        this.ready = true;
      }
    }
  },

  created() {
    window.mvAnalyticEvent = function(category, action, label, value) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: !isNaN(value) ? value : 1
      });
      //window.gtag("event", "t_" + action + "_" + label);
    };

    document.ondblclick = function(e) {
      e.preventDefault();
    };

    document.addEventListener("gesturestart", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    window.addEventListener("orientationchange", documentHeight);
    screen.orientation.addEventListener("change", documentHeight);

    documentHeight();
  }
};
</script>
<style lang="scss">
:root {
  --doc-height: 100%;
}
body {
  touch-action: manipulation;

  xxzoom: 0.9;
}

@import url("https://fonts.googleapis.com/css? family=Oxygen:300,400,500,700&display=swap");

$body-font-family: "Inter";
$title-font: "Inter";

body div.v-application.my-app,
body div.v-application.my-app .v-card__title,
body div.v-application.my-app .v-card__subtitle {
  font-family: $body-font-family, "Roboto", sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, "Roboto", sans-serif !important;
  }
}

xxx.v-application .text-h3 {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: $title-font, "Roboto", sans-serif !important;
}

.v-application .text-h1 {
  font-size: 38px !important;
  font-weight: bold !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application .text-h2 {
  font-size: 30px !important;
  font-weight: bold !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application .text-h3 {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application .text-h4 {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application .text-h5 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application .text-h6 {
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-transform: uppercase;
}

.v-application.xsOnly .text-h1 {
  font-size: 26px !important;
  font-weight: bold !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application.xsOnly .text-h2 {
  font-size: 22px !important;
  font-weight: bold !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application.xsOnly .text-h3 {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.v-application.xsOnly .text-h4 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.Point_Description_HTML h1,
.Point_Description_HTML h2,
.Point_Description_HTML h3,
.Point_Description_HTML h4,
.Point_Description_HTML h5 {
  margin-top: 12px;
}
</style>
