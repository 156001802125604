var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dataTableView"},[(false)?_c('div',{staticStyle:{"max-height":"calc(100vh - 156px)","overflow":"auto"}},_vm._l((_vm.listItems),function(item){return _c('div',{key:item.key},[_c('h1',[_vm._v(_vm._s(item.key))]),_c('h1',[_vm._v(_vm._s(item.data.name))]),_c('div',{staticClass:"Point_Description_HTML",domProps:{"innerHTML":_vm._s(item.data.structuredData.note.M_TA_description.value)}}),_c('hr'),_vm._v(" "+_vm._s(item.data.structuredData.note.M_TA_description.value)+" "),_c('hr')])}),0):_vm._e(),_c('v-card',{attrs:{"width":"100%","height":"100%"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.markerSearch),callback:function ($$v) {_vm.markerSearch=$$v},expression:"markerSearch"}})],1),_c('v-data-table',{staticClass:"dataTableWrapper",attrs:{"width":"100%","height":"100%","headers":[
        //{ text: 'Logo', value: 'logo', filterable: true },
        {
          text: 'Institution',
          value: 'list_section_title',
          filterable: true
        },
        { text: 'Campus', value: 'Point_Name', filterable: true },

        {
          text: 'Application type',
          value: 'InstituteType',
          filterable: true
        },

        {
          text: 'State',
          value: 'section_slug_upper',
          filterable: true
        },
        {
          text: 'Address',
          value: 'address',
          filterable: true,
          sortable: false
        },

        {
          text: 'Controls',
          value: 'post_id',
          filterable: false,
          sortable: false
        }
      ],"items":_vm.markerListData,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': [_vm.itemsPerPage ].concat( _vm.itemPerPageOption)
      },"search":_vm.markerSearch},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-img',{attrs:{"src":item.logo,"width":"50px"}})],1)]}},{key:"item.post_id",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"text":""},on:{"click":function ($event) {
                _vm.loadSite(item.index, { showMap: false });
              }}},[_vm._v(" Show More Info ")]),_c('v-btn',{attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function ($event) {
                _vm.loadSite(item.index, { showMap: true });
              }}},[_vm._v(" Show On Map ")])],1)]}},{key:"item.index",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"color":_vm.appSettings.dialogActionButtonColor,"text":""},on:{"click":function ($event) {
                _vm.loadSite(item.index, { showMap: true });
              }}},[_vm._v(" Show On Map ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }