<template>
  <div :class="markersScaleOpacityClass">
    <v-navigation-drawer
      touchless
      v-model="drawer"
      app
      right
      :width="
        $vuetify.breakpoint.smAndUp
          ? appSettings.drawWidth
          : appSettings.mobDrawWidth
      "
    >
      <SideBar />
    </v-navigation-drawer>

    <v-app-bar
      clipped-left
      app
      :color="appSettings.menuBarColor"
      :parentDrawer="drawer"
      class="secondary--text mainAppBar"
    >
      <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
      <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>

      <v-btn-toggle
        v-model="layoutMode"
        xborderless
        mandatory
        class="mainToggle greenGreyX  greyWhite"
      >
        <v-btn value="map" active-class="activeToggle">
          <span class="hidden-sm-and-down ">Map</span>

          <v-icon end>
            mdi-map
          </v-icon>
        </v-btn>

        <v-btn value="table" active-class="activeToggle">
          <span class="hidden-sm-and-down ">Table</span>

          <v-icon end>
            mdi-table-large
          </v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <mvTranslator v-if="false" :countries="countries" />

      <SoundCloudPlayer />

      <v-toolbar-title class="" v-if="playingSiteName">
        {{ playingSiteName }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <v-toolbar-title class="white--text notranslate" translate="no">
        <div class=" headingText siteHeadingText">
          <span class="font-weight-light">
            Maptivate
          </span>
          <span>UAC</span>
        </div>
      </v-toolbar-title>

      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = !drawer"
        class="xsecondary--text"
      ></v-app-bar-nav-icon>
      <LayersControl v-show="layoutMode == 'map'" />
    </v-app-bar>

    <v-main>
      <v-container fluid class="mapFrame" style="margin: 0;padding: 0;">
        <v-layout fill-height>
          <Map v-show="layoutMode == 'map'" />
          <Table v-show="layoutMode != 'map'" />
        </v-layout>
      </v-container>
    </v-main>

    <Legend v-show="layoutMode == 'map'" :drawer="drawer" />
    <YouTubeModal />

    <v-footer app :color="appSettings.menuBarColor" class="mainAppBar">
      <span>&copy; {{ new Date().getFullYear() }}</span>
      <v-spacer></v-spacer>

      <a
        class="linkButton"
        href="https://www.uac.edu.au/"
        target="_blank"
        style="text-decoration: none!important;"
      >
        <span class="mr-8">
          UAC
        </span>
      </a>

      <AcknowledgementModal class="mr-8" />
      <AboutModal class="mr-8" />
      <a
        class="linkButton"
        v-if="!$vuetify.breakpoint.xsOnly"
        href="https://www.maptivate.com.au/feedback"
        target="_blank"
        style="text-decoration: none!important;"
      >
        <span class=" mr-8"
          ><font v-if="!$vuetify.breakpoint.xsOnly">Report</font> Feedback
        </span>
      </a>
      <a
        class="linkButton"
        href="http://maptivate.com.au/"
        target="_blank"
        style="text-decoration: none!important;"
      >
        <span class="mr-8">
          <font v-if="!$vuetify.breakpoint.xsOnly">Powered by </font>

          Maptivate
        </span>
      </a>

      <ImageGalleryModal
        v-if="media && media.length"
        :width="appSettings.drawButtonWidth"
        :title="properties.Point_Name"
        :media="media"
      />
    </v-footer>
  </div>
</template>

<script>
import { eventBus } from "./main";

import { mapState } from "vuex";
//import RulesDialog from "./components/dialogs/RulesDialog";

import Table from "./components/table/Table.vue";
import Map from "./components/map/Map.vue";
import SideBar from "./components/SideBar.vue";
import AboutModal from "./components/info/AboutModal.vue";
import AcknowledgementModal from "./components/info/AcknowledgementModal.vue";
import YouTubeModal from "./components/controls/YouTubeModal.vue";

import SoundCloudPlayer from "./components/controls/SoundCloudPlayer.vue";
import ImageGalleryModal from "./components/controls/ImageGalleryModal.vue";

import LayersControl from "./components/mapelements/LayersControl.vue";
import Legend from "./components/mapelements/Legend.vue";

import mvTranslator from "./components/ui/mvTranslator.vue";

export default {
  components: {
    Map,
    SideBar,
    SoundCloudPlayer,
    YouTubeModal,
    LayersControl,
    AboutModal,
    AcknowledgementModal,
    ImageGalleryModal,
    Legend,
    Table,
    mvTranslator
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      sidebarSite: state => state.navigation.sidebarSite,

      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying,
      markersScaleOpacityClass: state => state.markers.markersScaleOpacityClass
    }),
    sites() {
      return this.markerList.data.features;
    },
    hasPlayingSiteName() {
      if (!this.playingSiteName || this.playingSiteName == "") {
        return false;
      } else {
        return true;
      }
    },

    properties() {
      try {
        if (
          this.sites &&
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          return this.sites[this.sidebarSite].properties;
        } else {
          //return null;
          throw { error: "error" };
        }
      } catch (error) {
        return null;
      }
    },
    media() {
      let media = null;

      if (this.properties && this.properties.media) {
        try {
          media = this.properties.media;

          media = JSON.parse(media);
        } catch (error) {
          //do nothing
        }
      }

      return media;
    }
  },
  data: () => ({
    layoutMode: "map",
    appTitle: "Maptivate",
    drawer: null,

    countries: [
      {
        code: "en|en",
        title: "English"
      },
      {
        code: "en|bn",
        title: "Bengali"
      },
      {
        code: "en|zh-CN",
        title: "Chinese (Simplified)"
      },
      {
        code: "en|zh-TW",
        title: "Chinese (Traditional)"
      },
      {
        code: "en|fr",
        title: "French"
      },
      {
        code: "en|de",
        title: "German"
      },
      {
        code: "en|el",
        title: "Greek"
      },
      {
        code: "en|hi",
        title: "Hindi"
      },
      {
        code: "en|it",
        title: "Italian"
      },
      {
        code: "en|ja",
        title: "Japanese"
      },
      {
        code: "en|ko",
        title: "Korean"
      },
      {
        code: "en|es",
        title: "Spanish"
      },
      {
        code: "en|iw",
        title: "Hebrew",
        flagIconUrl: "https://flagicons.lipis.dev/flags/4x3/il.svg",
        altText: "Hebrew language translation"
      }
    ]
  }),
  methods: {
    resetMap() {
      eventBus.$emit("nav_map_reset");
    },
    track() {
      this.$ga.page("/");
    },
    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    },
    setCommand(command) {
      this.$store.dispatch("mediaUpdateCommand", command);
    }
  },
  created() {
    eventBus.$on("nav_layout_openMenu", () => {
      this.drawer = true;
    });

    eventBus.$on("nav_layout_closeMenu", () => {
      this.drawer = false;
    });
    eventBus.$on("nav_SetTableMapView", value => {
      this.layoutMode = value === "map" ? "map" : "table";
    });

    this.drawer = this.$vuetify.breakpoint.smAndDown ? false : true;
    this.$store.dispatch("navUpdateLayoutDrawer", this.drawer);
  },

  watch: {
    layoutMode: function() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1);

      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200);
    },
    drawer: function(newValue) {
      this.$store.dispatch("navUpdateLayoutDrawer", newValue);
    }
  }
};
</script>

<style>
.mapElementBtn {
  border: thin solid #ffffff24 !important;
  border: 1.5pt solid #ffffff9c !important;
}

.mainAppBar.v-app-bar {
  border-bottom: 1.5pt solid #ffffff9c !important;
}

.mainAppBar.v-footer {
  border-top: 1.5pt solid #ffffff9c !important;
}

x.mainToggle .activeToggle,
x.mainToggle .activeToggle span,
x.mainToggle .activeToggle i {
  color: #1e2245 !important;
  background: white !important;
}

.mainToggle.greenGrey .v-btn {
  background: #394049 !important;
}
.mainToggle.greenGrey .v-btn,
.mainToggle.greenGrey .v-btn i {
  color: white !important;
}
.mainToggle.greenGrey .activeToggle {
  background: #448532 !important;
}

.mainToggle.greyWhite .v-btn {
  background: #394049 !important;
}
.mainToggle.greyWhite .v-btn,
.mainToggle.greyWhite .v-btn i {
  color: white !important;
}
.mainToggle.greyWhite .activeToggle {
  background: white !important;
}
.mainToggle.greyWhite .v-btn::before {
  opacity: 0 !important;
}
.mainToggle.greyWhite .v-btn.activeToggle,
.mainToggle.greyWhite .v-btn.activeToggle i {
  color: #1e2245 !important;
}

/* gets rid of the banner at the top of the page */
body {
  top: 0 !important;
}
/* get rids of the banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}

/* footer */

.v-footer,
.v-footer .linkButton,
.v-footer .linkButton {
  color: #f1f1f1 !important;
  color: #e9e9e9 !important;
}

.v-footer .linkButton:hover,
.v-footer .linkButton:active {
  color: white !important;
}

.mapFrame,
.mapWrapper,
#mapContainer {
  height: calc(100vh - 100px);

  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1263px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 100px);
  }
}
@media only screen and (max-width: 959px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 90px);
  }

  footer {
    font-size: 0.7em;
    height: 34px;
  }
}
.v-card__title {
  word-break: initial;
}

body div div.v-dialog {
  margin: 24px 6px;
}

@media only screen and (max-width: 960px) {
  .v-footer .mr-8 {
    margin-right: 8px !important;
  }
}

.stackedMarker {
  width: 46px;
  height: 46px;

  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;

  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.stackedMarker div img {
  -webkit-filter: drop-shadow(1px 1px 0 #00000075)
    drop-shadow(-1px -1px 0 #00000075);
  filter: drop-shadow(1px 1px 0 #00000075) drop-shadow(-1px -1px 0 #00000075);
}
.stackedMarker div {
  x_border: 1px solid red;
  position: absolute;
  height: 46px;
  visibility: hidden;
}

.stackedMarker div img {
  x_border: 1px solid black;
  cursor: pointer;
}

.stackedMarker div.image1 {
  margin-left: -3px;
  visibility: visible;
}
.stackedMarker div.image2 {
  margin-left: 3px;
  visibility: visible;
}
.stackedMarker div.image3 {
  margin-top: -5px;
  visibility: visible;
}

.markerOpaciyClass10 .stackedMarker {
  opacity: 1;
}
.markerOpaciyClass9 .stackedMarker {
  opacity: 0.9;
}
.markerOpaciyClass8 .stackedMarker {
  opacity: 0.8;
}
.markerOpaciyClass7 .stackedMarker {
  opacity: 0.7;
}
.markerOpaciyClass6 .stackedMarker {
  opacity: 0.6;
}
.markerOpaciyClass5 .stackedMarker {
  opacity: 0.5;
}
.markerOpaciyClass4 .stackedMarker {
  opacity: 0.4;
}
.markerOpaciyClass3 .stackedMarker {
  opacity: 0.3;
}
.markerOpaciyClass2 .stackedMarker {
  opacity: 0.2;
}

.markerOpaciyClass1 .stackedMarker {
  opacity: 0.1;
}
.markerOpaciyClass0 .stackedMarker {
  opacity: 0;
}

.markerScaleClass10 .stackedMarker {
  transform: scale(0.9);
}
.markerScaleClass9 .stackedMarker {
  transform: scale(0.8);
}
.markerScaleClass8 .stackedMarker {
  transform: scale(0.7);
}
.markerScaleClass7 .stackedMarker {
  transform: scale(0.5);
}
.markerScaleClass6 .stackedMarker {
  transform: scale(0.3);
}
.markerScaleClass5 .stackedMarker {
  transform: scale(0.25);
}
.markerScaleClass4 .stackedMarker {
  transform: scale(0.2);
}
.markerScaleClass3 .stackedMarker {
  transform: scale(0.15);
}
.markerScaleClass2 .stackedMarker {
  transform: scale(0.1);
}

.markerScaleClass1 .stackedMarker {
  transform: scale(0.05);
}
.markerScaleClass0 .stackedMarker {
  transform: scale(0.05);
}

//screen reader style

.mapboxgl-accessibility-marker {
  background: transparent;
  margin: 0;
  padding: 0;
  appearance: none;
  border-radius: 0;
  border: none;
  position: fixed;
}

xx .mapboxgl-accessibility-marker:focus {
  border: 2px solid black;
}

.mapboxgl-accessibility-marker {
  background: rgba(0, 0, 255, 0);
  margin: 0;
  padding: 0;
  xxborder: 5px solid pink;

  background: rgba(0, 0, 255, 0);

  border-radius: 20px;

  position: absolute;

  width: 40px !important;
  height: 40px !important;

  //background: red;
}

@media only screen and (max-width: 959px) {
  .mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin-right: 76px;
    margin-bottom: 4px;
  }
}
</style>
