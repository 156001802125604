const targets = [
  {
    postId: "markers",
    taxonomies: ["markersection", "marker_institute"],
    sectionTaxonomies: "markersection",
    listTaxonomies: "marker_institute",
    geoData: true
  },
  {
    postId: "artwork",
    taxonomies: ["artwork_section"],
    sectionTaxonomies: "artwork_section",
    geoData: true,
    forceMarkType: "artwork"
  }
  /*
  
  
    {
      postId: "setup",
      taxonomies: [],
      isSetupObject: true
    } ,
    {
      postId: "pages",
      taxonomies: [],
    },*/
];

module.exports = {
  targets
};
