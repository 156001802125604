import { eventBus } from "./../../../main";

export default {
  data: () => ({
    accessEvent: null
  }),

  computed: {},

  methods: {
    getMarkerIdFromAriaLabelAndLoadInfo(ariaLabel, layer, actionType) {
      this.clearTooltip();
      this.clearAllPopups();
      this.clearTooltip();
      this.clearAllPopups();

      if (layer.includes("_cluster")) {
        //do nothing!!!

        let features = this.map.querySourceFeatures("places");
        let [feature] = features.filter(
          feature => feature?.properties?.name === ariaLabel
        );

        if (actionType === "tooltip") {
          if (feature?.geometry?.coordinates) {
            //this.flyToPoint("markerList", site);

            let e = { features: [feature] };
            this.createTooltip(e);
          }
        } else if (actionType === "popup") {
          console.log("cluster popup;!!");
          if (feature) {
            try {
              let mappedMenuItems = feature.properties.marker_indexs
                .split(",")
                .filter(item => !isNaN(item.trim()) && (item || item === 0))
                .map(index => {
                  index = parseFloat(index);
                  return this?.sites[index]?.properties;
                });

              //this.map.fire('click', { lngLat: lngLat });

              console.log(feature.geometry.coordinates, { mappedMenuItems });

              setTimeout(() => {
                this.createStackedMenuPopup(
                  feature.geometry.coordinates,
                  mappedMenuItems,
                  { keyboard: true }
                );
              }, 50);
            } catch (error) {
              console.log("error!!1", { error });
            }
          }
        }
      } else if (layer.includes("_uncluster")) {
        this.clearTooltip();
        let foundSite = false;
        try {
          let [site] = this.sites.filter(row => {
            return row.properties.name == ariaLabel;
          });

          if (site) {
            foundSite = true;
          }

          if (foundSite) {
            if (actionType === "popup") {
              if (site?.geometry?.coordinates) {
                this.flyToPoint("markerList", site);
              }
            } else if (actionType === "tooltip") {
              if (site?.geometry?.coordinates) {
                //this.flyToPoint("markerList", site);

                let e = { features: [site] };
                this.createTooltip(e);
                //console.log(e);
              }
            }
          }
        } catch (error) {
          console.log({ error });
        }
      }
    },

    keyDownAccessibilityFunction(event) {
      let focusedElement = document.activeElement;

      //console.log(focusedElement);

      let skip = ["INPUT"];
      //check for control/alt/comand....

      if (!event.ctrlKey && !event.altKey && !event.metaKey) {
        try {
          if (
            focusedElement &&
            focusedElement.nodeName &&
            !skip.includes(focusedElement.nodeName)
          ) {
            if (event.key === "c") {
              //close an info windows
              eventBus.$emit("remote-ui-layercontrol-close");
              eventBus.$emit("remote-ui-legned-close");
              eventBus.$emit("nav_SetTableMapView", "map");
              //this.$store.dispatch("navUpdateSidebarSite", false);
              //this.$store.dispatch("navUpdateFocusSite", false);
              eventBus.$emit("nav_siteList_scrollToTop");
              eventBus.$emit("nav_sideBar_openSiteList");
              eventBus.$emit("nav_map_clearAllPopups");

              document.querySelector("#mapContainer .mapboxgl-canvas").focus();
            }

            if (event.key === "p") {
              //popup

              if (
                !isNaN(this.sidebarSite) &&
                (this.sidebarSite || this.sidebarSite === 0)
              ) {
                setTimeout(() => {
                  document
                    .querySelector(".mvSideBarTab .v-tab.v-tab--active")
                    .focus();
                }, 100);
              }
            }

            if (event.key === "i") {
              //more info trab

              if (
                !isNaN(this.sidebarSite) &&
                (this.sidebarSite || this.sidebarSite === 0)
              ) {
                eventBus.$emit("remote-ui-layercontrol-close");
                eventBus.$emit("remote-ui-legned-close");
                eventBus.$emit("nav_SetTableMapView", "map");
                eventBus.$emit("nav_layout_openMenu");

                eventBus.$emit("nav_sideBar_openMoreInfo");

                setTimeout(() => {
                  document
                    .querySelector(".mvSideBarTab .v-tab.v-tab--active")
                    .focus();
                }, 300);
              }
            }

            if (event.key === "l") {
              //list
              eventBus.$emit("remote-ui-layercontrol-close");
              eventBus.$emit("remote-ui-legned-close");
              eventBus.$emit("nav_SetTableMapView", "map");
              eventBus.$emit("nav_layout_openMenu");
              eventBus.$emit("nav_sideBar_openSiteList");
              document
                .querySelector(
                  "div.siteKeyAct_wrapper .v-list-group__header:first-child"
                )
                .focus();
            }

            if (event.key === "g") {
              //filters ??? G for filters not f???

              eventBus.$emit("nav_SetTableMapView", "map");
              eventBus.$emit("remote-ui-layercontrol-open");

              setTimeout(() => {
                document
                  .querySelector(
                    "div.layerWrapper .fakeCheckbox  button:first-child"
                  )
                  .focus();

                document
                  .querySelector("div.layerWrapper .fakeCheckbox  button")
                  .focus();
              }, 50);
            }

            if (event.key === "m") {
              //map
              eventBus.$emit("nav_SetTableMapView", "map");

              eventBus.$emit("remote-ui-layercontrol-close");
              eventBus.$emit("remote-ui-legned-close");
              //document.getElementById("mapContainer").focus();
              document.querySelector("#mapContainer .mapboxgl-canvas").focus();

              focusedElement = document.activeElement;
            }
            if (event.key === "t") {
              eventBus.$emit("remote-ui-layercontrol-close");
              eventBus.$emit("remote-ui-legned-close");
              eventBus.$emit("nav_SetTableMapView", "table");
            }
          }
        } catch (error) {
          console.log({ error });
        }

        if (event.key === "Tab") {
          // Get the currently focused element
          //let focusedElement = document.activeElement;
          // Log the tag name of the currently focused element

          setTimeout(() => {
            focusedElement = document.activeElement;

            let { tagName, tabIndex, dataset } = focusedElement;

            let layer = dataset?.layer;
            let title = dataset?.title;

            if (
              tagName == "BUTTON" &&
              tabIndex === 0 &&
              title &&
              layer.includes("cluster")
            ) {
              //check if element is a tab index
              //this.getMarkerIdFromAriaLabel(ariaLabel);
              this.getMarkerIdFromAriaLabelAndLoadInfo(title, layer, "tooltip");
            }
          }, 1);
        }

        if (event.keyCode === 32) {
          // Space bar was pressed
        }
        if (event.keyCode === 13) {
          // Enter was pressed

          // Get the currently focused element
          //let focusedElement = document.activeElement;
          // Log the tag name of the currently focused element
          let { tagName, tabIndex, dataset } = focusedElement;

          let layer = dataset?.layer;
          let title = dataset?.title;

          if (
            tagName == "BUTTON" &&
            tabIndex === 0 &&
            title &&
            layer.includes("cluster")
          ) {
            //check if element is a tab index
            this.getMarkerIdFromAriaLabelAndLoadInfo(title, layer, "popup");
          }
        }
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", this.keyDownAccessibilityFunction);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keyDownAccessibilityFunction);
    document.removeEventListener(
      "keydown",
      this.keyDownAccessibilityFunction,
      true
    );
  },

  created() {},
  destroyed() {
    document.removeEventListener("keydown", this.keyDownAccessibilityFunction);
    document.removeEventListener(
      "keydown",
      this.keyDownAccessibilityFunction,
      true
    );
  }
};
