<template>
  <div v-if="fosTypes">
    <v-card-text class="pt-0 pb-0">
      <template v-for="(value, key) in fosTypes">
        <div :key="'fos_' + key" v-if="value" class="pt-1 pb-2">
          <span class="text-h5 text--darken-1 ">
            {{
              value.label
                .replace("FOS", "field of study")
                .replace("Institute", "Institution")
                .trim()
            }}:
          </span>

          {{
            value.value
              .join(", ")
              .replace(
                "Environmental and related Studies",
                "Environmental and Related Studies"
              )
          }}
        </div>
      </template>
    </v-card-text>
  </div>
</template>
<script>
export default {
  name: "fosList",
  components: {},
  props: {
    structuredData: Object
  },
  data() {
    return {};
  },
  computed: {
    fosTypes() {
      let M_TA_campousFos = this.structuredData?.note?.M_TA_campousFos
        ? this.structuredData.note.M_TA_campousFos
        : null;

      try {
        M_TA_campousFos.value = JSON.parse(M_TA_campousFos.value);
      } catch (error) {
        //do nothing
      }

      if (M_TA_campousFos) return { M_TA_campousFos };
      else return false;
    }
    /*
    fosTypes() {


      let M_TA_campousFos = this.structuredData?.note?.M_TA_campousFos
          ? this.structuredData.note.M_TA_campousFos
          : null,
        M_TA_instituteFos = this.structuredData?.note?.M_TA_instituteFos
          ? this.structuredData.note.M_TA_instituteFos
          : null;

      try {
        M_TA_campousFos.value = JSON.parse(M_TA_campousFos.value);
      } catch (error) {
        //do nothing
      }
      try {
        M_TA_instituteFos.value = JSON.parse(M_TA_instituteFos.value);
      } catch (error) {
        //do nothing
      }

      try {
        if (
          JSON.stringify(M_TA_instituteFos.value) ===
          JSON.stringify(M_TA_campousFos.value)
        ) {
          M_TA_instituteFos = null;
        }
      } catch (error) {
        //do nothing
      }

      if (M_TA_campousFos || M_TA_instituteFos)
        return { M_TA_campousFos, M_TA_instituteFos };
      else return false;
    }*/
  }
};
</script>

<style lang="scss" scoped></style>
