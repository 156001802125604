<template>
  <div
    style="width:0;height:0;
    overflow: visible;"
  >
    <div class="mobileSet layerControlWrapper">
      <div class="layerControlButtonsr mvButtonWrapper" v-if="!showLayer">
        <v-btn
          :title="`${!showLayer ? 'Show' : 'Close'} Layer Filters`"
          z-depth="0"
          depressed
          notsmall
          dark
          class="ml-2 pa-0 mapElementBtn"
          :color="!showLayer ? appSettings.mediaButtonColor : 'white'"
          @click="showLayer = !showLayer"
        >
          <v-icon v-if="showLayer">mdi-close-circle </v-icon>
          <v-icon v-if="!showLayer">mdi-layers-outline </v-icon>
        </v-btn>
      </div>
      <v-expand-transition>
        <div v-show="showLayer" class="layerWrapper" style="">
          <v-card>
            <v-toolbar color="" dense height="35" flat>
              <v-toolbar-title>Layer Filters</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="showLayer = false"
                title="Close Layer Filters"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-row
              class="px-0 pb-0 ma-0 layerFilterItems"
              justify="space-between"
              style=""
            >
              <div class="ml-4  layerLegendHeading">Application type:</div>

              <v-btn-toggle
                class="fakeCheckbox"
                style="flex-direction: column; ;"
                v-model="applicationTypeFilters"
              >
                <v-btn
                  v-for="item in applicationTypeButtons"
                  :key="item.value"
                  :value="item.value"
                  v-bind="iconSize"
                  :color="
                    applicationTypeFilters.includes(item.value)
                      ? '#f1f1f1'
                      : 'null'
                  "
                  style=""
                >
                  <v-icon
                    v-bind="iconSize"
                    left
                    v-if="!applicationTypeFilters.includes(item.value)"
                    >mdi-checkbox-blank-outline
                  </v-icon>
                  <v-icon
                    v-bind="iconSize"
                    left
                    v-if="applicationTypeFilters.includes(item.value)"
                    >mdi-checkbox-intermediate
                  </v-icon>

                  <div
                    class="checkboxDiv"
                    :class="
                      applicationTypeFilters.includes(item.value)
                        ? 'secondary--text'
                        : 'secondary--text  text--lighten-2 '
                    "
                  >
                    {{ item.title }}
                  </div>
                </v-btn>
              </v-btn-toggle>

              <div class="ml-4  layerLegendHeading">State:</div>
              <v-btn-toggle
                class="fakeCheckbox"
                v-model="layers"
                multiple
                style="flex-direction: column; ;"
              >
                <v-btn
                  v-for="item in setup.bools"
                  v-show="categoryLookup[item.key].visible"
                  :key="item.key"
                  :value="item.key"
                  v-bind="iconSize"
                  :color="layers.includes(item.key) ? '#f1f1f1' : null"
                  style=""
                >
                  <v-icon
                    v-bind="iconSize"
                    left
                    v-if="!layers.includes(item.key)"
                    :color="categoryLookup[item.key].color"
                    >mdi-checkbox-blank-outline
                  </v-icon>
                  <v-icon
                    v-bind="iconSize"
                    left
                    v-if="layers.includes(item.key)"
                    :color="categoryLookup[item.key].color"
                    >mdi-checkbox-intermediate
                  </v-icon>

                  <div
                    class="checkboxDiv"
                    :class="
                      layers.includes(item.key)
                        ? 'secondary--text'
                        : 'secondary--text  text--lighten-2 '
                    "
                  >
                    {{ item.title }}
                  </div>
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>
<script>
import { eventBus } from "../../main";

import { mapState } from "vuex";
export default {
  name: "LayerControls",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      categoryLookup: state => state.markers.categoryLookup,
      setup: state => state.filters.setup,
      colors: state => state.filters.colors
    }),
    iconSize() {
      const size = { xs: "medium", sm: "medium", lg: "medium", xl: "medium" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },

    applicationTypeFilters: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.applicationTypeFilters;
      },
      set(value) {
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdateAppType", value);
      }
    },
    layers: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.layers;
      },
      set(value) {
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdateLayers", value);
      }
    }
  },
  data: () => ({
    readOnlyButtonValues: [],
    show: false,
    showLayer: false,
    hasChangedLayer: false,

    applicationTypeButtons: [
      {
        title: "Show all",
        value: "All"
      },
      {
        title: "Apply through UAC only",
        value: "Participating"
      },
      {
        title: "Apply directly to institution only",
        value: "Apply Direct"
      }
    ]
  }),
  methods: {
    remoteClose() {
      this.showLayer = false;
    },
    remoteOpen() {
      this.showLayer = true;
    }
  },
  created() {
    eventBus.$on("remote-ui-layercontrol-open", this.remoteOpen);
    eventBus.$on("remote-ui-layercontrol-close", this.remoteClose);
  },
  beforeDestroy() {
    eventBus.$off("remote-ui-layercontrol-open", this.remoteClose);
    eventBus.$off("remote-ui-layercontrol-close", this.remoteClose);
  },

  watch: {
    showLayer: function(newValue) {
      if (newValue === true) {
        window.mvAnalyticEvent("ui", "layerControlsOpen", "open", false);

        eventBus.$emit("remote-ui-legned-close");
      }
    }
  }
};
</script>

<style scoped>
.layerLegendHeading {
}

.layerControlWrapper {
  transition: all 0.5s;
  top: 72px;
  margin-left: auto;
  position: absolute;
  /* border-radius: 2px; */
  /* width: 500px; */
  right: 6px;
}

.mobileSet {
}
.layerWrapper {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  position: absolute;
  right: 0;
  top: 0;
}

.fakeCheckbox button {
  height: auto !important;
  text-transform: none;
}
.fakeCheckbox button {
  border-radius: 0 !important;
  justify-content: left;
  font-weight: 400;
}
.fakeCheckbox div {
  width: 300px;
  white-space: none;
  white-space: normal;

  text-align: left;
  height: auto;

  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
}

.layerFilterItems {
  max-height: calc(100vh - 116px);
  overflow: auto;
}

@media only screen and (max-width: 959px) {
  .layerControlWrapper {
    top: 62px;
  }

  .fakeCheckbox button {
    border-radius: 0 !important;
    justify-content: left;
  }
  .fakeCheckbox div {
    width: 450px;
    max-width: calc(100vw - 62px);
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 2px;
  }
}

@media only screen and (min-width: 960px) {
  .fakeCheckbox button {
    min-height: 26px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}
</style>
