<template>
  <div v-if="value && value.length">
    <v-card-text class="pt-1 pb-2">
      <span class="text-h5 text--darken-1 ">
        Course types:
      </span>
      {{ value }}
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "studyType",
  components: {},
  props: {
    structuredData: Object
  },
  data() {
    return {};
  },
  computed: {
    value() {
      try {
        let undergraduate =
          this.structuredData?.value?.M_C_YN_undergraduate?.value === "Yes"
            ? ["Undergraduate"]
            : [];
        let postgraduate =
          this.structuredData?.value?.M_C_YN_postgraduate?.value === "Yes"
            ? ["Postgraduate"]
            : [];
        let international =
          this.structuredData?.value?.M_C_YN_international?.value === "Yes"
            ? ["International"]
            : [];

        return [...undergraduate, ...postgraduate, ...international].join(", ");
      } catch (error) {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
