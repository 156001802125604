<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? 500 : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="linkButton">
          <font v-if="!$vuetify.breakpoint.xsOnly" class="secondary--text ">
          </font
          >Acknowledgement<font v-if="!$vuetify.breakpoint.xsOnly">
            of Country
          </font>
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-card-title
          :style="{
            background: 'rgb(100 54 91)'
          }"
          class="  xlighten-2 white--text text-center justify-center siteHeadingText"
        >
          Acknowledgement of Country
        </v-card-title>

        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row
            v-if="$vuetify.breakpoint.mdAndUp"
            class="contentWrapper noOverflowOnWide"
            style="background-image: url(assets/Dreams-by-Adam-Hennessy-purple-version.jpg);background-size: cover; 
    min-height: 207px;
    background-repeat: no-repeat;
    border-bottom: 15px #64365b solid;"
          >
            <v-col cols="4" class="" style=""> </v-col>
            <v-col md="4" cols="4" class="ma-0 pa-0 bodyTextAck" style="">
              <v-card-text>
                <center>
                  <h3 class="white--text font-weight-light">
                    UAC acknowledges and pays respect to the past, present and
                    future traditional custodians and elders of this nation. We
                    honour the continuation of cultural, spiritual and
                    educational practices of Aboriginal and Torres Strait
                    Islander peoples.
                  </h3>
                </center>
              </v-card-text>
            </v-col>
          </v-row>

          <v-row
            class="contentWrapper noOverflowOnWide"
            v-if="!$vuetify.breakpoint.mdAndUp"
            :style="{
              background: 'rgb(100 54 91)'
            }"
          >
            <v-col
              cols="12"
              style="overflow:hidden;max-height:100%"
              class="pa-3"
            >
              <v-img
                v-if="
                  $vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.smAndDown
                "
                class="white--text align-end  ma-0"
                src="assets/Dreams-by-Adam-Hennessy-purple-version.jpg"
              >
              </v-img>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0 bodyTextAck" style="">
              <v-card-text>
                <center>
                  <h3 class="white--text font-weight-light">
                    UAC acknowledges and pays respect to the past, present and
                    future traditional custodians and elders of this nation. We
                    honour the continuation of cultural, spiritual and
                    educational practices of Aboriginal and Torres Strait
                    Islander peoples.
                  </h3>
                </center>
              </v-card-text>
            </v-col>
          </v-row>

          <v-row class="contentWrapper noOverflowOnWide">
            <v-col md="12" cols="12" class="ma-0 pa-0 bodyTextAck" style="">
              <v-card-text>
                <center>
                  <v-col
                    md="12"
                    cols="12"
                    class="ma-0 pt-0 bodyTextAck"
                    style=""
                  >
                    <v-card-text class="pt-0">
                      <center>
                        <p class="pt-0">
                          Original artwork 'Dreams' by artist Adam Hennessy –
                          The circles represent coming together. They form a
                          pathway around the outside, symbolising an educational
                          journey. The circles in the top right are our
                          institutions gathered to fulfil the dreams of the
                          travellers.
                        </p>
                      </center>
                    </v-card-text>
                  </v-col>
                </center>
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>

          <v-btn
            v-if="false"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="loadAbout()"
          >
            About Maptivate
          </v-btn>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "AcknowledgementModal",
  components: {},
  data() {
    return {
      openIntro: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Point_Name
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
    getAudioDetails(name) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name
      ) {
        isPlayingThisTrack = true;
      }
      if (this.playingSiteName && this.playingSiteName === name) {
        isLoadedThisTrack = true;
      }
      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },

    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    }
  },
  created() {
    eventBus.$on("loadIntroPopup", () => {
      this.openIntro = true;
    });
  },
  mounted() {
    setTimeout(() => {
      this.openIntro = true;
    }, 300);
  }
};
</script>

<style>
@media only screen and (min-width: 960px) {
  .noOverflowOnWide {
    overflow: hidden;
  }

  .v-application div.bodyTextAck {
    margin-bottom: -18px !important;
    padding-top: 5px !important;
  }
}

@media only screen and (max-width: 959px) {
  .v-application div.bodyTextAck {
    padding-top: 0 !important;
  }

  .noOverflowOnWide {
    margin: 0 !important;
  }
}
</style>
