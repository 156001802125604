<template>
  <v-card elevation="4" v-if="ready">
    <v-card-title
      :class="appSettings.headingTextColor"
      v-if="false && mappedMenuItemsFilter.length"
      >has items</v-card-title
    >
    <v-list
      dense
      :max-height="$vuetify.breakpoint.mobile ? '230px' : '310px'"
      :class="$vuetify.breakpoint.mobile ? 'custScroller' : 'custScroller'"
      style="overflow-y:auto"
      v-model="selected"
    >
      <template v-for="(item, itemIndex) in mappedMenuItemsFilter">
        <v-list-item
          :aria-label="`${item.list_section_title}, ${item.Point_Name}`"
          class="stackedMenuClickItem"
          :key="item.index"
          @click="
            loadMarkerByIdFromMenu(item.index);
            popupClicked(item);
          "
        >
          <v-list-item-avatar size="24" class="ml-0 mr-2">
            <v-avatar
              size="22"
              :color="
                item.section_slug && categoryLookup[item.section_slug]
                  ? categoryLookup[item.section_slug].color
                  : ''
              "
            ></v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="grey--text font-weight-normal">
              {{ item.list_section_title }}
            </v-list-item-title>
            <v-list-item-subtitle class="grey--text font-weight-normal">
              {{ item.Point_Name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="item.header"
          v-if="mappedMenuItemsFilter.length - 1 != itemIndex"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    selected: null,
    ready: false,
    showCarousel: false
  }),

  props: {
    options: Object,
    getAudioDetails: Function,
    loadMarkerByIdFromMenu: Function,
    appSettings: Object,
    categoryLookup: Object,
    mappedMenuItems: {
      required: true,
      type: Array
    },
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    mappedMenuItemsFilter() {
      //let items = [];
      let found = {};
      return this.mappedMenuItems.filter(row => {
        let pass = found[row.slug] ? false : true;
        found[row.slug] = row;
        return pass;
      });
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    setTimeout(() => {
      this.ready = true;
      this.showCarousel = true;

      if (this?.options?.keyboard === true) {
        this.selected = 0;

        try {
          document.querySelector(".stackedMenuClickItem:first-child").focus();
          document
            .querySelector(".stackedMenuClickItem:first-child > div")
            .focus();
        } catch (error) {
          //do nothing;
        }
        setTimeout(() => {
          console.log("focus!!");
          try {
            document.querySelector(".stackedMenuClickItem:first-child").focus();
            document
              .querySelector(".stackedMenuClickItem:first-child> div")
              .focus();
          } catch (error) {
            //do nothing;
          }
        }, 500);
      }
    }, 10);
  },
  methods: {
    popupClicked(properties) {
      //window.gtag("event", "markerClick", properties.Site_Marker_Number);
      window.mvAnalyticEvent(
        "map",
        "markerClick",
        properties.Site_Marker_Number,
        false
      );
    }
  }
});
</script>

<style>
.mapboxgl-popup-content {
  box-shadow: none;
}
.galMenu,
.galMenu .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galMenu {
  height: 90px;
}
.galMenu .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
}

.custScroller::-webkit-scrollbar {
  width: 1em;
}

.custScroller::-webkit-scrollbar-track {
  xbox-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f1f1f1;
}

.custScroller::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #a1a1a1;
}

.pointPopupTooltip {
  margin-top: 10px;
  max-width: 340px !important;
}
.pointPopupTooltip .mapboxgl-popup-content {
  border-radius: 4px;
  padding: 2px 4px;
  text-align: center;
}

.pointPopupTooltip .mapboxgl-popup-tip {
  display: none;
}

.mvToolTip {
  font-size: 13px;
  font-weight: 700;
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px !important;
}
</style>
