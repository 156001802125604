<template>
  <div>
    <maptivateInfoButton
      :isPopup="isPopup"
      butType="link"
      :width="width"
      :color="appSettings.mediaButtonColor"
      :icon="'mdi-open-in-new'"
      :title="title"
      :href="url"
      target="_blank"
    />
  </div>
</template>
<script>
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "LinkButton",
  components: {
    maptivateInfoButton
  },
  props: {
    appSettings: Object,
    width: String,
    url: String,
    title: String,

    isPopup: Boolean
  },
  methods: {},
  async mounted() {}
};
</script>
